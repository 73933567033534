import * as React from 'react'
import { Link } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'

const containerStyles = {
  margin: `3rem auto`,
  maxWidth: 800,
}

const About = () => {
  return (
    <div className="w3-container" style={containerStyles}>
      <Header />
      <main className="w3-padding w3-padding-24">
        <h1>About Lasso</h1>
        <Link to="/">Home</Link>
      </main>
      <Footer />
    </div>
  )
}

export default About
